
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form } from 'react-bootstrap';
import "./cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import { getAPICallDataForCadreSaving } from '../../services/MemberService';
import LanguageSelectionPopup from "./LanguageSelectionPopup";
import AOS from 'aos';
import 'aos/dist/aos.css';

class CadreRegistrationSelectSection extends Component {
    constructor(props) {
        super(props)
        

        this.state = {
            chatID: window.User !=undefined ? window.User.chatID : "943827154",
            userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
            
            selectMemberTypeBlock:true,

            lifeTimeEnrollCheck:false,
            generalEnrollCheck:false,

            languageSelectionModal:false,
            englishSelected:false,
            teleguSelected:false,
            volunteerMobileNoInput:"",
            volunteerCouponsCount:"",
            isVolunteer:"",
 
        }
    }

    componentDidMount() {
        AOS.init();
        
        console.log("userAccessList",this.state)
			
        if(this.state.userAccessList == ""){
            this.props.history.push("/dashboard");
        }else{
            this.setState({languageSelectionModal:true});
            this.getMobileNoByChatId();
        }
    }

    
    typeOfLanguageSelection = (e,languageType) => {
        this.setState({languageSelectionModal:false});

        if(languageType == "english"){
            this.setState({englishSelected:true,teleguSelected:false})
        }else{
            this.setState({englishSelected:false,teleguSelected:true})
        }
    }

    typeOfLanguageSelectionNew = (e,languageType) => {
    
        if(languageType == "english"){
            this.setState({englishSelected:true,teleguSelected:false})
        }else{
            this.setState({englishSelected:false,teleguSelected:true})
        }
    }

    getMobileNoByChatId = () => {
        
        const obj =  
        JSON.stringify({ 
           "chatId" :this.state.chatID,
        }); 
    
        getAPICallDataForOnlyBSA(obj,"getMobileNoByChatId")
        .then((res) => res.json())
        .then((data) => {
           if(data !=null && data.mobileNo !=null){
            this.getVolunteerDetailsByMobileNo(data.mobileNo);
           }
        });
    }

    
    getVolunteerDetailsByMobileNo = (mobileNo) => {
    const obj =  
        JSON.stringify({ 
            "volunteerMobileNo" : mobileNo
        });

        getAPICallDataForCadreSaving(obj,"getVolunteerDetailsByMobileNo")
        .then((res) => res.json())
        .then((content) => {
            if(content !=null ){
                this.setState({isVolunteer:content.isVolunteer});
                if(content.isVolunteer == "Y"){
                    this.setState({volunteerCouponsCount:content.couponsCount,volunteerMobileNoInput:content.mobileNo});
                }else{
                    this.setState({volunteerCouponsCount:"",volunteerMobileNoInput:""});
                }
               }else{
                this.setState({volunteerCouponsCount:"",volunteerMobileNoInput:""});
               }
        });
}


    lifeTimeMemberShipCheck = (e,type) => {
        if(type == "lifeTimeMem"){
            this.setState({lifeTimeEnrollCheck:true,generalEnrollCheck:false})
        }else{
            this.setState({lifeTimeEnrollCheck:false,generalEnrollCheck:true})
        }
    }
   
    
    render() {
        
       
        return (
            <section className="mb-4">
              <div className="header_cadre_img"></div>

              <Container className="mt-2">
                <Row>
                    <Col>
                        <Link to={{pathname: "/dashboard"}}> 
                            <Button variant="outline-dark pad5_14" className='f-12 home_btn fw-bold'> <i class="fa-solid fa-house"></i> Home</Button> 
                        </Link>
                    </Col>
                </Row>

                <Row>
                        <Col sm={12}>
                            <Form>
                                <div className='float-end'>
                                <Form.Check
                                    inline
                                    label={`English Version`}
                                    name="languageSelectionNew"
                                    type="radio"
                                    id={`englishddVersionId`}
                                    onChange={(e)=>this.typeOfLanguageSelectionNew(e,"english")}
                                    className="f-12 fw-bold color_red cursor_pointer"
                                    value="english"
                                    checked={this.state.englishSelected}
                                />
                                <Form.Check
                                    inline
                                    label={`తెలుగు వెర్షన్`}
                                    name="languageSelectionNew"
                                    type="radio"
                                    id={`teleguddVersionId`}
                                    onChange={(e)=>this.typeOfLanguageSelectionNew(e,"telegu")}
                                    className="f-12 fw-bold color_red cursor_pointer"
                                    value="telegu"
                                    checked={this.state.teleguSelected}
                                />
                                </div>
                            </Form>
                                
                        </Col>
                    </Row>

                    {
                        this.state.isVolunteer == "Y" ?
                        (
                            <Row>
                                <Col className='mt-3'>
                                {
                                    this.state.volunteerCouponsCount == 0 ?
                                    (<h6 className='f-12'>Coupons Not Available, Please Contact Party Office</h6>)
                                    :
                                    (<h6 className='f-12'>Available Coupons : <span className='fw-bold f-14'>{this.state.volunteerCouponsCount}</span></h6>)
                                }
                                    
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }


                {
                    this.state.selectMemberTypeBlock == true ?
                    (
                        <div className='bg_white_shadow_border mt-3' data-aos="slide-right" data-aos-delay="100">
                            <h6 className='text-center  fw-bold'>{this.state.englishSelected== true ? "SELECT MEMBERSHIP TYPE" : "మెంబర్‌షిప్ రకాన్ని ఎంచుకోండి"}</h6>
                            <Row>
                                <Col sm={12}>
                                    <Form>
                                        <div className='mt-2 text-center'>
                                        <Form.Check
                                            inline
                                            label={this.state.englishSelected== true ? `LIFE TIME` : `లైఫ్ టైమ్ నమోదు `}
                                            name="lifeTimeMemberShipNameCls"
                                            type="radio"
                                            id={`lifeTimeMemId`}
                                            onChange={(e)=>this.lifeTimeMemberShipCheck(e,"lifeTimeMem")}
                                            className="f-14 fw-bold color_black cursor_pointer"
                                            defaultChecked={false}
                                            value="lifeTimeMem"
                                        />
                                        <Form.Check
                                            inline
                                            label={this.state.englishSelected== true ? `GENERAL` : `సాధారణ నమోదు `}
                                            name="lifeTimeMemberShipNameCls"
                                            type="radio"
                                            id={`generalMemId`}
                                            onChange={(e)=>this.lifeTimeMemberShipCheck(e,"generalMem")}
                                            className="f-14 fw-bold color_black cursor_pointer"
                                            value="generalMem"
                                        />
                                        
                                        </div>
                                    </Form>
                                        
                                </Col>
                            </Row>
                        </div>
                    )
                    :
                    ("")
                }
                

                {
                    this.state.lifeTimeEnrollCheck == true ?
                    (
                        this.props.history.push({
                            pathname: "/cadre_life_time_reg",
                            params: {
                                navbarDisplay:"hide",
                                userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                englishSelected:this.state.englishSelected,
                                teleguSelected:this.state.teleguSelected,
                            
                            },
                        })
                        
                    )
                    :
                    (
                        this.state.generalEnrollCheck == true ? 
                        (
                            this.props.history.push({
                                pathname: "/cadre_general_reg",
                                params: {
                                    navbarDisplay:"hide",
                                    userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList,
                                    englishSelected:this.state.englishSelected,
                                    teleguSelected:this.state.teleguSelected,
                                
                                },
                            })
                        )
                        :
                        ("")
                    )     
                }

            </Container>

            <LanguageSelectionPopup
                    show={this.state.languageSelectionModal}
                    onHide={() => this.setState({ languageSelectionModal: false })}
                    stateData={this.state}
                    typeOfLanguageSelection={this.typeOfLanguageSelection}
                    
            />
                
        </section>
        )
    }
  }
  
  export default withRouter(CadreRegistrationSelectSection);
  