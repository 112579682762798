import React, { Component } from 'react'
import { Container, Button,Row,Col,Card,Form,InputGroup,Image } from "react-bootstrap";
import loader from '../../images/loader.gif';
import { withRouter,Link } from "react-router-dom";
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import { getAPICallDataForCadre } from '../../services/MemberService';

class LifeTimeEnrollReg extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            englishSelected:props?.location?.params?.englishSelected == undefined ? true : this.props?.location?.params?.englishSelected,
            teleguSelected:props?.location?.params?.teleguSelected == undefined ? false : this.props?.location?.params?.teleguSelected,

            captureMobileNo:"",
            validateCaptureMobileNo:"",
            captureOTPNO:"",
            validateCaptureOTP:"",

            mobileNoDisabled:false,

            smsSentMessage:"",
            otpVerifiedMessageStatus:"",
            otpLoadingSymbol:"hide",
            getOTPLoadingSymbol:"hide",
            OTPSentStatus:"",
            resendOTPLoadingSymbol:"hide",
            resendSmsSentMessage:"",

            mobileNoLoadingSymbol:"hide",
            mobileNoSuccessMsg:"",
            
            minutes:1,
            seconds:0,
            OTPVerifiedDisabledButton:true,
        }
      
    }

    componentDidMount() {
       
    }

    typeOfLanguageSelectionNew = (e,languageType) => {
   
        if(languageType == "english"){
            this.setState({englishSelected:true,teleguSelected:false})
        }else{
            this.setState({englishSelected:false,teleguSelected:true})
        }
    }

    backToNewCadreEnrollPage = () => {
        this.props.history.push({
            pathname: "/cadre_registration",
            params: {
                navbarDisplay:"hide",
                userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList
              },
          });
    }

    handleOnchangeInputBox = (e) => {
        console.log("handleInputChange", e.target.name, e.target.value)
        const name = e.target.name;
        const value = e.target.value;

        if(name == "captureOTPNO"){
            this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
            if(value.length >= 6){
                this.validateMobileOtp(value);
               
            }
            
        }

        this.setState({ [name]: value });
        
      }
    
getMobileNoOTP = () => {
    this.setState({validateCaptureMobileNo:"" });
    var expr = /^(0|91)?[6-9][0-9]{9}$/;

    if(this.state.captureMobileNo == ""){
        this.setState({validateCaptureMobileNo:"Please Enter Mobile No" });
        return;
    }

    if(!expr.test(this.state.captureMobileNo)){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    if(this.state.captureMobileNo.length < 10){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }


    this.setState({smsSentMessage:"",getOTPLoadingSymbol:"show",OTPSentStatus:"",otpVerifiedValue:0});
    
    const obj =  
        JSON.stringify({ 
        "chatId" :this.state.chatID,
        "mobileNo" : this.state.captureMobileNo,
        "volunteerMobileNo" : this.state.volunteerMobileNoInput,
        
        }); 

        getAPICallDataForOnlyBSA(obj,"sendOTPForCitVlorRegistration")
        .then((res) => res.json())
        .then((data) => {
        this.setState({getOTPLoadingSymbol:"hide"});
        if(data !=null && data.status == "Success"){
           // console.log(jwt_decode(data.reference).sub)
            this.setState({smsSentMessage:"OTP Sent Your Mobile No",OTPSentStatus:data.status,otpVerifiedValue:1});
            this.setState({mobileNoDisabled:true,OTPVerifiedDisabledButton:false})
            this.OTPTimerFunction();
        }else if(data !=null && data.status == "Fail"){
            this.setState({smsSentMessage:data.message,OTPSentStatus:"Fail",otpVerifiedValue:0});
            this.setState({mobileNoDisabled:false,OTPVerifiedDisabledButton:true})
        }else{
            this.setState({smsSentMessage:"Some Thing Went Wrong,Please Try Again...",OTPSentStatus:"Fail",otpVerifiedValue:0});
            this.setState({mobileNoDisabled:false,OTPVerifiedDisabledButton:true})
        }
        });
}

resendOTPTimer = () => {
    this.setState({minutes:1});
    this.setState({seconds:0});

    this.OTPTimerFunction();
};

OTPTimerFunction = () => {
    const interval = setInterval(() => {
        if (this.state.seconds > 0) {
        this.setState({seconds:this.state.seconds - 1});
        }

        if (this.state.seconds === 0) {
        if (this.state.minutes === 0) {
            clearInterval(interval);
        } else {
            this.setState({seconds:120});
            this.setState({minutes:this.state.minutes - 1});

        }
        }
    }, 1000);

    return () => {
        clearInterval(interval);
    };
}

resendOTPNew = () => {
    this.setState({validateCaptureMobileNo:"",OTPVerifiedDisabledButton:false });
    if(this.state.captureMobileNo == ""){
        this.setState({validateCaptureMobileNo:"Please Enter Mobile No" });
        return;
    }
    var expr = /^(0|91)?[6-9][0-9]{9}$/;
    if(!expr.test(this.state.captureMobileNo)){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    

    if(this.state.captureMobileNo.length < 10){
        this.setState({validateCaptureMobileNo:"Please Enter Valid Mobile No" });
        return;
    }

    this.setState({resendOTPLoadingSymbol:"show",otpVerifiedValue:0});

    const obj =  
        JSON.stringify({ 
           "chatId" :this.state.chatID,
            "mobileNo" : this.state.captureMobileNo,
            "volunteerMobileNo" : this.state.volunteerMobileNoInput,
        
        }); 

        getAPICallDataForOnlyBSA(obj,"sendOTPForCitVlorRegistration")
        .then((res) => res.json())
        .then((data) => {
        this.setState({resendOTPLoadingSymbol:"hide"});
        if(data !=null && data.status == "Success"){
            //console.log(jwt_decode(data.reference).sub)
            this.setState({otpVerifiedValue:1});
            this.resendOTPTimer();
        }else{
            this.setState({otpVerifiedValue:0});
        }
        });
    
}

validateMobileOtp = (otp) => {
this.setState({otpVerifiedMessageStatus:"",otpLoadingSymbol:"show"});
const obj =  
    JSON.stringify({ 
    "chatId" :this.state.chatID,
    "mobileNo" : this.state.captureMobileNo,
    "otp" : otp,
    "volunteerMobileNo" : this.state.volunteerMobileNoInput,
    

    }); 

    getAPICallDataForOnlyBSA(obj,"verifyCitVlorRegistrationOTP")
    .then((res) => res.json())
    .then((data) => {
    this.setState({otpLoadingSymbol:"hide"});
    if(data !=null && data.status == "Success"){
        this.setState({otpVerifiedMessageStatus:"Verified",OTPVerifiedDisabledButton:true});
    }else{
        this.setState({otpVerifiedMessageStatus:"NotVerified",OTPVerifiedDisabledButton:false});
    }
    });
}
  
    render() {
        console.log("xcdd",this.props.location?.params?.imageBase64Str)
        return (
            <section className="mb-4">
              <div className="header_cadre_img"></div>
              <Container>
            <Row>
                <Col>
                    <Button variant="outline-dark pad5_14" className='f-12 fw-bold float-end mt-2' onClick={() => this.backToNewCadreEnrollPage()}> <i class="fa-solid fa-arrow-left"></i> Back</Button> 
                </Col>
            </Row>
            <Row>
                <Col>
                    <h6 className='text-center color_green fw-bold  mt-3'>{this.state.englishSelected== true ? <span className='border-bottom-double'>LIFE TIME ENROLLMENT</span> : <span className='border-bottom-double'>లైఫ్ టైమ్ నమోదు</span>}</h6>
                </Col>
            </Row>

                <fieldset class=""><legend>{this.state.englishSelected== true ? "Mobile No & Validate OTP" : "ఫోన్ నెంబర్ & OTP"}  </legend>  
                    <Row>
                        <Col className='mt-2'>
                            <Form>
                                <Form.Group className="" controlId="formMobileId">
                                    <Form.Label className='f-12 fw-bold'>{this.state.englishSelected== true ? "Mobile No" : "ఫోన్ నెంబర్"} :<span className='color_red f-14 ms-1'>*</span> <span className='color_red f-9'>{this.state.validateCaptureMobileNo}</span>
                                    {
                                        this.state.mobileNoLoadingSymbol == "show" ?
                                        (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls1'></Image>)
                                        :
                                        (
                                            ""
                                        )
                                    }
                                    {
                                        this.state.mobileNoSuccessMsg == "SUCCESS"  ?
                                        (<i class="fa-regular fa-circle-check color_green f-14"></i>)
                                        :
                                        (
                                            this.state.mobileNoSuccessMsg == "FAIL" || this.state.mobileNoSuccessMsg == "Something Wrong,Please Try Again..." ?
                                            (<i class="fa-regular fa-circle-xmark color_red f-14"></i>)
                                            :
                                            ("")
                                            
                                        )
                                    }
                                    </Form.Label>
                                    <Row>
                                        <Col className='width_90_static'> 
                                        <Form.Control type="text" name="captureMobileNo" disabled={this.state.mobileNoDisabled} className='form-control-sm' maxLength={10} value={this.state.captureMobileNo}  placeholder="Enter Mobile No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                            
                                        </Col>
                                        <>
                                        <Col className='width_10_static'>
                                    
                                            {
                                                this.state.mobileNoSuccessMsg == "SUCCESS" || this.state.mobileNoSuccessMsg == ""  ?
                                                (
                                                    <>
                                                    {
                                                        this.state.OTPSentStatus == "Fail" || this.state.OTPSentStatus == "" ?
                                                        (
                                                            <>
                                                                <Button variant="dark" className='f-12 float-right  btn-sm' onClick={() => this.getMobileNoOTP()}>GET OTP
                                                                    {
                                                                        this.state.getOTPLoadingSymbol == "show" ?
                                                                        (<Image src={loader} alt="loader" className='get_otp_loading_symbol_cls22'></Image>)
                                                                        :
                                                                        ("")
                                                                    }  
                                                                </Button> 
                                                            </>
                                                        )
                                                        :
                                                        ("")
                                                    }
                                                    </>
                                                )
                                                :
                                                (
                                                    ""
                                                )
                                            }
                                            </Col>

                                        
                                                {
                                                        this.state.OTPSentStatus == "Fail" || this.state.OTPSentStatus == "" ?
                                                        (
                                                            <>
                                                            <h6 className='f-12 color_red'>{this.state.smsSentMessage}</h6>
                                                                
                                                            </>
                                                        )
                                                        :
                                                        ("")
                                                    }
                                            </>
                                        
                                    </Row>
                                    
                                    
                                </Form.Group>  
                            </Form>

                            <Form>
                                        <Row>
                                            
                                            <Col className=''>
                                            <InputGroup className="mt-3">
                                                        <InputGroup.Text id="basic-addon1" className='f-12' style={{textAlign:"left",marginLeft:"0px"}}>{this.state.englishSelected== true ? "OTP NO" : "OTP నెంబర్"} <span className='color_red f-14 ms-1'>*</span>  </InputGroup.Text>
                                                        <Form.Control type="text" name="captureOTPNO" maxLength={6} disabled={this.state.OTPVerifiedDisabledButton} className='form-control-sm' value={this.state.captureOTPNO}  placeholder="Enter OTP No" onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                            </InputGroup>
                                            <h6 className='color_red f-12 mt-2 font-weight-bold'>{this.state.validateCaptureOTP}</h6>
                                            </Col>
                                            
                                        </Row>
                                        
                                            <Row>
                                                <Col>
                                                    {
                                                        this.state.OTPSentStatus == "Success"?
                                                        (
                                                            <>
                                                                <h6 className='mt-2 f-12 color_green line_height_20'>{this.state.smsSentMessage} : <b >{this.state.captureMobileNo}</b></h6>
                                                                
                                                                {
                                                                    this.state.otpVerifiedMessageStatus != "Verified" || this.state.otpVerifiedMessageStatus == "" ?
                                                                    (
                                                                        <div className="countdown-text">
                                                                            {this.state.seconds > 0 || this.state.minutes > 0 ? (
                                                                            <h6 className='mt-2 f-14'>
                                                                                {this.state.englishSelected== true ? "Time Remaining" : "మిగిలిన సమయం"} : {this.state.minutes < 10 ? `0${this.state.minutes}` : this.state.minutes}:
                                                                                {this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}
                                                                            </h6>
                                                                            ) : (
                                                                            <h6 className='mt-2 f-12'>{this.state.englishSelected== true ? "Didn't recieve code?" : "కోడ్ అందలేదా?"} </h6>
                                                                            )}
                                                                            {
                                                                                this.state.resendOTPLoadingSymbol == "show" ?
                                                                                (<Image src={loader} alt="loader" className='ms-2 get_otp_loading_symbol_cls'></Image>)
                                                                                :
                                                                                ("")
                                                                            } 
                                                                            <Button variant="dark" className='f-12 float-right mt-2' 
                                                                            disabled={this.state.seconds > 0 || this.state.minutes > 0}
                                                                            style={{
                                                                                color: this.state.seconds > 0 || this.state.minutes > 0 ? "#DFE3E8" : "#fff",
                                                                            }} onClick={this.resendOTPNew}  >Resend OTP</Button>
                                                                            
                                                                        </div>
                                                                    )
                                                                    :
                                                                    ("")
                                                                }
                                                            
                                                                
                                                            </>
                                                        )
                                                        :
                                                        ("")
                                                    }
                                                </Col>
                                            </Row>
            
                                            <Row>
                                                <Col>
                                                    {
                                                            this.state.otpLoadingSymbol == "show" ?
                                                            (<Image src={loader} alt="loader" className='loader_cls_otp ms-2'></Image>)
                                                            :
                                                            (
                                                                this.state.otpVerifiedMessageStatus == "Verified" ?
                                                                (
            
                                                                    <h6 class="color_green fw-bold f-14  text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>{this.state.englishSelected== true ? "OTP verified successfully" : "OTP విజయవంతంగా ధృవీకరించబడింది"} </h6>
                                                                    
                                                                )
                                                                :
                                                                (
                                                                    this.state.otpVerifiedMessageStatus == "NotVerified" ?
                                                                    (<h6 class="color_red fw-bold f-14 text-center mt-2"><i class="fa-solid fa-check mr-2"> </i>{this.state.englishSelected== true ? "Not Verified,Please Enter Valid OTP" : "దయచేసి చెల్లుబాటు అయ్యే OTPని నమోదు చేయండి"} </h6>)
                                                                    :
                                                                    ("")
                                                                    
                                                                
                                                                )
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        
                                    </Form>

                            

                                
                        </Col>
                    </Row>
                </fieldset>
                
                <Row>
                    <Col>
                        <div className='bg_white_shadow_border mt-3'>
                            Some Text Some TextSome TextSome TextSome TextSome TextSome Text

                            <Button variant="danger" className="red_button_event center-auto mt-2"  >PAY NOW </Button>
                        </div>
                    </Col>
                </Row>
                </Container>
                </section>
                
            
        )
    }
}
export default withRouter(LifeTimeEnrollReg);
  

