import React, { Component } from "react";
import './scripts/telegram.js'
import './scripts/UserContextProvider.js'
import './App.css';
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from "./components/NavBar";
import Dashboard from "./components/Dashboard/Dashboard";
import BoothAndHouseMapping from "./components/BoothAndHouseMapping/BoothAndHouseMapping";
import EventsAndActivities from "./components/EventsAndActivities/EventsAndActivities";
import BoothWiseAllHouses from './components/BoothWiseAllHouses/BoothWiseAllHouses';
import BoothWiseDashboard from './components/BoothWiseDashboard/BoothWiseDashboard';
import DisplayGoogleMap from './components/MapComponent/DisplayGoogleMap';
import OngoingAndReady from "./components/EventsAndActivities/OngoingAndReady";
import ImageCaptureBlock from "./components/ImageCaptureSection/ImageCaptureBlock";
import LiveEventPhotosCapture from "./components/EventsAndActivities/LiveEventPhotosCapture";
import EventMaterialSection from "./components/EventsAndActivities/EventMaterialSection";
import SocialMediaSection from "./components/SocialMedia/SocialMediaSection";
import QuestionerFormSection from "./components/EventsAndActivities/QuestionerFormSection";
import PDFViewerSection from "./components/PDFViewer/PDFViewerSection";
import MLCVotersSection from "./components/EventsAndActivities/MLCVotersSection";
import PollManagementSection from "./components/PollManagement/PollManagementSection";
import VerifyHouseDashboard from "./components/Dashboard/VerifyHouseDashboard";
import ProgramsDashboard from "./components/Dashboard/ProgramsDashboard";
import QrReaderCode from "./components/QRScannerCode/QrReaderCode";
import VolunteerSection from "./components/Volunteer/VolunteerSection";
import ManifestDetails from "./components/ManifestDetailsSection/ManifestDetails";
import EnrollmentsSection from "./components/Volunteer/EnrollmentsSection";
import SuccessPage from "./components/Volunteer/SuccessPage";
import VoterSearchEnrollSave from "./components/Volunteer/VoterSearchEnrollSave";
import AttributesCollections from "./components/AttributesCollections/AttributesCollections";
import AttributesCollectionsDashboard from "./components/AttributesCollections/AttributesCollectionsDashboard";
import VotersCollections from "./components/VotersCollections/VotersCollections";
import VotersCollectionDashboard from "./components/VotersCollections/VotersCollectionDashboard";
import RaiseAnIssueSection from "./components/RaiseAnIssues/RaiseAnIssueSection.js";
import VoterSearch from "./components/VoterSearch/VoterSearch.js";
import VotersListRevision from "./components/VotersListRevision/VotersListRevision";
import HelpPageForm from "./components/VotersListRevision/HelpPageForm";
import VoiceRecorder from "./components/VoiceRecorder/VoiceRecorder";
import PerformanceReportSection from "./components/PerformanceReport/PerformanceReportSection"
import ElectionCampaignSection from "./components/ElectionCampaign/ElectionCampaignSection";
import WardCampaignStatus from "./components/WardCampaignStatus/WardCampaignStatus";
import PollManagementPanchayatElection from "./components/PollManagementPanchayatElection/PollManagementPanchayatElection";
import VolunteerDashboard from "./components/Volunteer/VolunteerDashboard";
import ViewDescription from "./components/Dashboard/ViewDescription";
import KSSDashboard from "./components/Dashboard/KSSDashboard";
import VotersModificationDashboard from "./components/VotersModification/VotersModificationDashboard";
import VotersVerification from "./components/VotersVerification/VotersVerification";
import SharingActivity from "./components/SharingActivity/SharingActivity";

import VolunteerSectionLocation from "./components/CheckingLocation/VolunteerSectionLocation";
import SuccessPageLocation from "./components/CheckingLocation/SuccessPageLocation";
import VoterSearchEnrollSaveLocation from "./components/CheckingLocation/VoterSearchEnrollSaveLocation";
import BoothWiseNearestPlacesSection from "./components/BoothWiseNearestPlaces/BoothWiseNearestPlacesSection";

import HouseVisitedDisplayGoogleMap from "./components/MapComponent/HouseVisitedDisplayGoogleMap";
import YuvagalamVehicleTracking from "./components/YuvagalamVehicleTracking/YuvagalamVehicleTracking";
import VotersFormsVerification from "./components/VotersFormsVerification/VotersFormsVerification";
import VotersFormDashboard from "./components/VotersFormsVerification/VotersFormDashboard";
import LeaderSearchSection from "./components/LeaderSearch/LeaderSearchSection";
import LeaderDashboardSection from "./components/LeaderSearch/LeaderDashboardSection";
import LeaderNotMatchedRecordsSection from "./components/LeaderSearch/LeaderNotMatchedRecordsSection";
import FirstTimeVoters from "./components/FirstTimeVoters/FirstTimeVoters";
import LeaderMatchedRecordsSection from "./components/LeaderSearch/LeaderMatchedRecordsSection";
import BSBGVerificationSection from "./components/BSBGVerification/BSBGVerificationSection";
import BSBGDashboard from "./components/BSBGVerification/BSBGDashboard";
import PublicOutreachSection from "./components/PublicOutreach/PublicOutreachSection";
import GenerateQRCodeSection from "./components/GenerateQRCode/GenerateQRCodeSection";
import AttendenceSection from "./components/Attendence/AttendenceSection";
import AttendenceDashboard from "./components/Attendence/AttendenceDashboard";
import PublicOutReachDashboard from "./components/PublicOutreach/PublicOutReachDashboard";
import VotersMigration from "./components/VotersMigration/VotersMigration";
import PublicOutReachVotersSection from "./components/PublicOutReachNew/PublicOutReachVotersSection";
import PublicOutReachDashboardNew from "./components/PublicOutReachNew/PublicOutReachDashboardNew";
import ElectionActivitiesSection from "./components/ElectionActivities/ElectionActivitiesSection";
import ElectionActivitiesSaving from "./components/ElectionActivities/ElectionActivitiesSaving";

import BSBGVerificationSectionNew from "./components/BSBGVerificationNew/BSBGVerificationSectionNew";
import BSBGDashboardNew from "./components/BSBGVerificationNew/BSBGDashboardNew";
import MyTeamSection from "./components/MyTeam/MyTeamSection";

import BoothLocationMappingSection from "./components/BoothLocationMapping/BoothLocationMappingSection";
import SingleVoterSearch from "./components/VoterSlipDistributedCampign/SingleVoterSearch";
import PolledStatusSingleVoterSearch from "./components/PolledStatusCampaign/PolledStatusSingleVoterSearch";
import ExitPollSingleVoterSearch from "./components/ExitPollCampaign/ExitPollSingleVoterSearch";
import SendVoterSlipSection from "./components/SendVoterSlip/SendVoterSlipSection";
import CadreRegistrationGeneralSection from "./components/CadreRegistration/CadreRegistrationGeneralSection";
import DisplayCardDetails from "./components/CadreRegistration/DisplayCardDetails";
import CadreRegistrationUserWiseDashboard from "./components/CadreRegistration/CadreRegistrationUserWiseDashboard";
import LifeTimeEnrollReg from "./components/CadreRegistration/LifeTimeEnrollReg";
import CadreRegistrationSelectSection from "./components/CadreRegistration/CadreRegistrationSelectSection";

//import MemberForm from "./components/MemberForm";

class App extends Component {
  render() {
    return (
      <Router>
        <Navbar/>
          <Switch>
              <Route
                  exact
                  path="/"
                  render={(props) => (
                      <Dashboard
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/dashboard"
                  render={(props) => (
                      <Dashboard
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/verify_house"
                  render={(props) => (
                      <BoothAndHouseMapping
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/all_houses"
                  render={(props) => (
                      <BoothWiseAllHouses
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/booth_wise_analysis"
                  render={(props) => (
                      <BoothWiseDashboard
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/events"
                  render={(props) => (
                      <EventsAndActivities
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/ongoing_ready_event"
                  render={(props) => (
                      <OngoingAndReady
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/image_capture"
                  render={(props) => (
                      <ImageCaptureBlock
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/live_event_photos_capture"
                  render={(props) => (
                      <LiveEventPhotosCapture
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/event_material"
                  render={(props) => (
                      <EventMaterialSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/map_view_component"
                  render={(props) => (
                      <DisplayGoogleMap
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/social_media"
                  render={(props) => (
                      <SocialMediaSection
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/questionerForm"
                  render={(props) => (
                      <QuestionerFormSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/pdf_viewer"
                  render={(props) => (
                      <PDFViewerSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/mlc_voters"
                  render={(props) => (
                      <MLCVotersSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/poll_management"
                  render={(props) => (
                      <PollManagementSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/verify_house_dashboard"
                  render={(props) => (
                      <VerifyHouseDashboard
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/programs_dashboard"
                  render={(props) => (
                      <ProgramsDashboard
                          {...props}

                      />
                  )}
              />
             
              <Route
                  exact
                  path="/qr_reader"
                  render={(props) => (
                      <QrReaderCode
                          {...props}

                      />
                  )}
                />

                <Route
                  exact
                  path="/volunteer_page"
                  render={(props) => (
                      <VolunteerSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/manifest_page"
                  render={(props) => (
                      <ManifestDetails
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/enrollment_page"
                  render={(props) => (
                      <EnrollmentsSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/success_page"
                  render={(props) => (
                      <SuccessPage
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/voter_search_enroll_save"
                  render={(props) => (
                      <VoterSearchEnrollSave
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/attributes_collection"
                  render={(props) => (
                      <AttributesCollections
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/attributes_collection_dashboard"
                  render={(props) => (
                      <AttributesCollectionsDashboard
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/voters_collections"
                  render={(props) => (
                      <VotersCollections
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/voters_collections_dashboard"
                  render={(props) => (
                      <VotersCollectionDashboard
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/raiseIssue"
                  render={(props) => (
                      <RaiseAnIssueSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/voter_search"
                  render={(props) => (
                      <VoterSearch
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/voters_list_revision"
                  render={(props) => (
                      <VotersListRevision
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/help_page_form"
                  render={(props) => (
                      <HelpPageForm
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/voice_recorder"
                  render={(props) => (
                      <VoiceRecorder
                          {...props}

                      />
                  )}
              />
               <Route
                  exact
                  path="/performance_report"
                  render={(props) => (
                      <PerformanceReportSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/election_campaign"
                  render={(props) => (
                      <ElectionCampaignSection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/ward_campaign"
                  render={(props) => (
                      <WardCampaignStatus
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/poll_manag_panch_elec"
                  render={(props) => (
                      <PollManagementPanchayatElection
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/enrollment_dashboard"
                  render={(props) => (
                      <VolunteerDashboard
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/view_description"
                  render={(props) => (
                      <ViewDescription
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/kss_dashboard"
                  render={(props) => (
                      <KSSDashboard
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/voter_modification_dashboard"
                  render={(props) => (
                      <VotersModificationDashboard
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/voter_verification"
                  render={(props) => (
                      <VotersVerification
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/sharing_activity"
                  render={(props) => (
                      <SharingActivity
                          {...props}

                      />
                  )}
              />
              
              {/*  Checking Location   */}
              <Route
                  exact
                  path="/volunteer_page_location"
                  render={(props) => (
                      <VolunteerSectionLocation
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/success_page_location"
                  render={(props) => (
                      <SuccessPageLocation
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/voter_search_enroll_save_location"
                  render={(props) => (
                      <VoterSearchEnrollSaveLocation
                          {...props}

                      />
                  )}
              />
              <Route
                  exact
                  path="/boothWiseNearestPlaces"
                  render={(props) => (
                      <BoothWiseNearestPlacesSection
                          {...props}

                      />
                  )}
              />
              
              <Route
                  exact
                  path="/map_view_houseVisited"
                  render={(props) => (
                      <HouseVisitedDisplayGoogleMap
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/yuvagalam_vehicle_tracking"
                  render={(props) => (
                      <YuvagalamVehicleTracking
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/voters_forms_verification"
                  render={(props) => (
                      <VotersFormsVerification
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/forms_data_validation_report"
                  render={(props) => (
                      <VotersFormDashboard
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/leader_search_page"
                  render={(props) => (
                      <LeaderSearchSection
                        {...props}
                      />
                  )}
              />
                <Route
                  exact
                  path="/leader_dashboard"
                  render={(props) => (
                      <LeaderDashboardSection
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/leader_notMatched_details"
                  render={(props) => (
                      <LeaderNotMatchedRecordsSection
                        {...props}
                      />
                  )}
              />
              
              <Route
                  exact
                  path="/first_time_voter_search"
                  render={(props) => (
                      <FirstTimeVoters
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/leader_matched_details"
                  render={(props) => (
                      <LeaderMatchedRecordsSection
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/bsbg_shankharavam"
                  render={(props) => (
                      <BSBGVerificationSection
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/bsbg_dashboard"
                  render={(props) => (
                      <BSBGDashboard
                        {...props}
                      />
                  )}
              />
             <Route
                  exact
                  path="/bsbg_publicOutreach"
                  render={(props) => (
                      <PublicOutreachSection
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/generate_qrCode"
                  render={(props) => (
                      <GenerateQRCodeSection
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/qr_attendence"
                  render={(props) => (
                      <AttendenceSection
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/attendence_dashboard"
                  render={(props) => (
                      <AttendenceDashboard
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/publicOutReach_dashboard"
                  render={(props) => (
                      <PublicOutReachDashboard
                        {...props}
                      />
                  )}
              />
              
              <Route
                  exact
                  path="/publicOutReach_new"
                  render={(props) => (
                      <PublicOutReachVotersSection
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/publicOutReach_dashboard_new"
                  render={(props) => (
                      <PublicOutReachDashboardNew
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/voters_migration"
                  render={(props) => (
                      <VotersMigration
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/election_activities"
                  render={(props) => (
                      <ElectionActivitiesSection
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/election_activity_saving"
                  render={(props) => (
                      <ElectionActivitiesSaving
                        {...props}
                      />
                  )}
              />
              
              <Route
                  exact
                  path="/bsbg_shankharavam_new"
                  render={(props) => (
                      <BSBGVerificationSectionNew
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/bsbg_dashboard_new"
                  render={(props) => (
                      <BSBGDashboardNew
                        {...props}
                      />
                  )}
              />
               <Route
                  exact
                  path="/my_team"
                  render={(props) => (
                      <MyTeamSection
                        {...props}
                      />
                  )}
              />

            <Route
                  exact
                  path="/booth_location_mapping"
                  render={(props) => (
                      <BoothLocationMappingSection
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/voter_slip_campaign"
                  render={(props) => (
                      <SingleVoterSearch
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/voter_status_campaign"
                  render={(props) => (
                      <PolledStatusSingleVoterSearch
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/exit_poll_campaign"
                  render={(props) => (
                      <ExitPollSingleVoterSearch
                        {...props}
                      />
                  )}
              />

                <Route
                  exact
                  path="/send_voter_slip"
                  render={(props) => (
                      <SendVoterSlipSection
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/cadre_general_reg"
                  render={(props) => (
                      <CadreRegistrationGeneralSection
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/display_card_success_page"
                  render={(props) => (
                      <DisplayCardDetails
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/cadre_reg_dashboard"
                  render={(props) => (
                      <CadreRegistrationUserWiseDashboard
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/cadre_life_time_reg"
                  render={(props) => (
                      <LifeTimeEnrollReg
                        {...props}
                      />
                  )}
              />
              <Route
                  exact
                  path="/cadre_registration"
                  render={(props) => (
                      <CadreRegistrationSelectSection
                        {...props}
                      />
                  )}
              />
              
              
              
          </Switch>
    </Router>
    )
  }
}
export default App;

