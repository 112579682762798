import React, { Component } from 'react'
import { Modal, Button,Row,Col,Form,Image,InputGroup } from "react-bootstrap";
import loader from '../../images/loader.gif';
import Webcam from "react-webcam";

class CapturePhotoForEnroll extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
          videoConstraints:{facingMode: "environment"},

          
          
        }
      
    }

  
   
switchCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "environment"}});
  }
  frontCamaraForHouseVisit = () => {
    this.setState({videoConstraints:{facingMode: "user"}});
  }
  
    render() {
        return (
            <Modal {...this.props} 
            size="sm"   aria-labelledby="example-modal-sizes-title-lg" className="houseVisitCaptureCls">
              <Modal.Header closeButton className='p-3'>
                <Modal.Title className='f-14 fw-bold'> Image Capture For Cadre Enrollment</Modal.Title>
              </Modal.Header>
                <Modal.Body className="">
                    <>
                    <Row>
                        <Col sm={12}>
                            <Form className="">
                                <Form.Check
                                        inline
                                        label={"లైవ్ ఫోటో తీయటం"}
                                        name="image_capture"
                                        type="radio"
                                        id="image_capture_upload"
                                        onChange={()=>this.props.selectCaptureTypeNew("camara")}
                                        className="f-12 fw-bold"
                                        checked={this.props.stateData.camaraRadioButtonNew}
                                    />
                                    <Form.Check
                                        inline
                                        label="ఫోటో అప్లోడ్ చేయండి"
                                        name="image_capture_upload"
                                        type="radio"
                                        id="upload_photos"
                                        onChange={()=>this.props.selectCaptureTypeNew("upload")}
                                        className="f-12 fw-bold"
                                        checked={this.props.stateData.uploadImageRadioButtonNew}
                                    />
                            </Form>
                        </Col>
                        </Row>

                        {
                            this.props.stateData.selectedTypePhotoNew == "camara" ?
                            (
                                <>
                                <h6 className='f-12 color_red'>{this.props.stateData.validateActivityImageUpload}</h6>
                                <Row>
                                    <Col className="mt-2">
                                            <div className="showImgCaptureDivBSBG">{this.props.stateData.showActivityImgCaptureNew && (
                                            <> 
                                            <Webcam
                                                audio={false}
                                                ref={this.props.stateData.webcamRef}
                                                screenshotFormat="image/jpeg"
                                                videoConstraints={this.state.videoConstraints}
                                            /> 
                                            
                                            
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <h6 onClick={() => this.props.captureForActivityImageUploadNew()} className="camara_icon"><i class="fa-solid fa-camera" ></i></h6>
                                                </div>
                                                <div className="col">
                                                    {
                                                    this.state.videoConstraints.facingMode == "user" ?
                                                    (<h6 className="switch_camara text-center" onClick={() => this.switchCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to back camara</h6>)
                                                    :
                                                    ( <h6 className="switch_camara text-center" onClick={() => this.frontCamaraForHouseVisit()} ><i class="fa-sharp fa-solid fa-repeat"></i> Switch to front camara</h6>)
                                                    }
                                                </div>
                                            </div>

                                            
                                            </>
                                            
                                            )}
                                            </div>
                                        </Col>
                                    </Row>
                                    </>
                            )
                            :
                            (
                                <Row>
                                    <Col className="mt-2">
                                    <div className="form-group">
                                        <h6 className='f-12 color_red'></h6>
                                        <label for="uploadfilesForLive" class="drop-container">
                                            <span class="drop-title"><i class="fa-solid fa-cloud-arrow-up"></i></span>
                                            <input type="file" id="uploadfilesForLive" className='f-14' onChange={this.props.uploadMultipleFilesForPhotosNew}/>
                                        </label>
                                    </div>
                                    <h6 className='f-12 color_red mt-2'>{this.props.stateData.manualUploadImageNew}</h6>
                                    </Col>
                                </Row>
                            )
                        }

                        {
                            this.props.stateData.photoCaptureForActivityArrNew.length>0 ?
                            (
                            <>
                                <Row>
                                <Col sm={12} className="mt-2">
                                    {
                                        this.props.stateData.selectedTypePhotoNew == "camara" ? 
                                        (<h6>Capture Photos : </h6>)
                                        :
                                        (<h6>Upload Images: </h6>)
                                    }
                                
                                </Col>
                                <Col sm={12} className="">
                                    <div className='flex_img'>
                                        {(this.props.stateData.photoCaptureForActivityArrNew || []).map((url,index) => (
                                            <div className='ms-1 border_img_pad'>
                                                <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                            </div>
                                        ))} 
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <Button variant="danger" className="red_button_event center-auto mt-3" onClick={() => this.props.uploadImageForMembership()}>UPLOAD </Button>
                                </Col>
                            </Row>
                            </>
                            
                            
                            )
                            :
                            ("")
                        }
                    </>
                  
                </Modal.Body>
            </Modal>
            
        )
    }
}
export default CapturePhotoForEnroll;
  

