import React, { Component } from 'react'
import { Container, Button,Row,Col,Card,Form } from "react-bootstrap";
import loader from '../../images/loader.gif';
import QRCode from "react-qr-code";
import { withRouter,Link } from "react-router-dom";
class DisplayCardDetails extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            englishSelected:props?.location?.params?.englishSelected == undefined ? true : this.props?.location?.params?.englishSelected,
            teleguSelected:props?.location?.params?.teleguSelected == undefined ? false : this.props?.location?.params?.teleguSelected,
        }
      
    }

    componentDidMount() {
       
    }

    typeOfLanguageSelectionNew = (e,languageType) => {
   
        if(languageType == "english"){
            this.setState({englishSelected:true,teleguSelected:false})
        }else{
            this.setState({englishSelected:false,teleguSelected:true})
        }
    }

    backToNewCadreEnrollPage = () => {
        this.props.history.push({
            pathname: "/cadre_registration",
            params: {
                navbarDisplay:"hide",
                userAccessList:this.props?.location?.params?.userAccessList == undefined ? "" : this.props?.location?.params?.userAccessList
              },
          });
    }
  
    render() {
        console.log("xcdd",this.props.location?.params?.imageBase64Str)
        return (
                <section className="mb-4">
                    <div className="header_cadre_img"></div>

                <Container className="mt-2">
                <Row>
                        <Col sm={12}>
                            <Form>
                                <div className='float-end'>
                                <Form.Check
                                    inline
                                    label={`English Version`}
                                    name="languageSelectionNew"
                                    type="radio"
                                    id={`englishddVersionId`}
                                    onChange={(e)=>this.typeOfLanguageSelectionNew(e,"english")}
                                    className="f-12 fw-bold color_red cursor_pointer"
                                    value="english"
                                    checked={this.state.englishSelected}
                                />
                                <Form.Check
                                    inline
                                    label={`తెలుగు వెర్షన్`}
                                    name="languageSelectionNew"
                                    type="radio"
                                    id={`teleguddVersionId`}
                                    onChange={(e)=>this.typeOfLanguageSelectionNew(e,"telegu")}
                                    className="f-12 fw-bold color_red cursor_pointer"
                                    value="telegu"
                                    checked={this.state.teleguSelected}
                                />
                                </div>
                            </Form>
                                
                        </Col>
                    </Row>

                <Row>
                            <Col sm={12}>
                                <h6 className='text_center fw-bold f-14'>{this.state.englishSelected == true ? "Your Application submitted Successfully" : "సభ్యత్వం నమోదు అయినందుకు ధన్యవాదములు"}</h6>
                                <h6 className='text_center mt-1 color_green fw-bold f-14'>{this.state.englishSelected == true ? "MemberShip No" : "సభ్యత్వం నెంబర్" } : <span className='f-18'>{this.props.location?.params?.cardDetailsDisplay?.mid}</span></h6>
                            <div className='htmlToImageVis' ref={this.myref}> 
                                    <Card className="text-white border-0 mt-2">
                                        <Card.Img src={require('../../images/front_mc.jpeg')} alt="Card image"  />
                                            <div className='qr_code_pos_card'>
                                                <QRCode
                                                    size={65}
                                                    viewBox={`0 0 260 260`}
                                                    value={`mid-${this.props.location?.params?.cardDetailsDisplay?.mid}`}
                                                />
                                            </div>
                                        
                                        <Card.ImgOverlay className=''>
                                            <Row>
                                                <Col>
                                                    <div className='flex-card'>
                                                        <div>
                                                            {
                                                                this.props.location?.params?.imageBase64Str == "" || this.props.location?.params?.imageBase64Str == undefined ?
                                                                (<Card.Img src={require('../../images/human.jpg')} alt="Card image" className="member_img"  />)
                                                                :
                                                                (<Card.Img src={this.props.location?.params?.imageBase64Str} alt="Card image" className="member_img"  />)
                                                            }
                                                            
                                                        </div>
                                                        <div>
                                                            <div className='bg_red_card'>
                                                                <Card.Title className='mb-1 fw-bold f-12'>{this.props.location?.params?.cardDetailsDisplay?.mid}</Card.Title>
                                                                <Card.Title className='mb-0  fw-bold f-12'>2024-25</Card.Title>
                                                            </div>
                                                            <div className='mt-1 ml-2'>
                                                                <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.props.location?.params?.cardDetailsDisplay?.cardDetails?.cname}</Card.Title>
                                                                {
                                                                    this.props.location?.params?.cardDetailsDisplay?.cardDetails?.villageName == "" && this.props.location?.params?.cardDetailsDisplay?.cardDetails?.mandalName == "" ?
                                                                    (
                                                                        <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.props.location?.params?.cardDetailsDisplay?.cardDetails?.townName} - మున్సిపాలిటీ </Card.Title>
                                                                    )
                                                                    :
                                                                    (
                                                                        this.props.location?.params?.cardDetailsDisplay?.cardDetails?.townName == ""  ?
                                                                        (
                                                                            <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.props.location?.params?.cardDetailsDisplay?.cardDetails?.villageName} - గ్రామం , {this.props.location?.params?.cardDetailsDisplay?.cardDetails?.mandalName} - మండలం</Card.Title>
                                                                        )
                                                                        :
                                                                        ("")
                                                                    )
                                                                }
                                                                
                                                                <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.props.location?.params?.cardDetailsDisplay?.cardDetails?.constituencyName} - నియోజకవర్గం , {this.props.location?.params?.cardDetailsDisplay?.cardDetails?.districtName} - జిల్లా </Card.Title>
                                                                <Card.Title className='mb-1 fw-bold f-10 color_black'>{this.props.location?.params?.cardDetailsDisplay?.cardDetails?.stateName}</Card.Title>
                                                            </div>
                                                        </div>
                                                        

                                                        

                                                    </div>
                                                </Col>
                                            </Row>
                                        
                                            
                                            
                                        </Card.ImgOverlay>
                                    </Card>

                                    <Card className="text-white mt-2">
                                        <Card.Img src={require('../../images/back_mc.jpeg')} alt="Card image"  />
                                    </Card>


                                    </div>
                                <div className='text-center'>
                                    <Row>
                                        
                                        <Col>
                                            <Button variant="warning" className='mt-4 f-14 fw-bold color_white' onClick={() => this.backToNewCadreEnrollPage()}>Start New Enroll</Button>
                                        </Col>
                                    </Row>

            
                                </div>
                                
                            </Col>
                        </Row>    
                </Container>
                
            </section>
            
        )
    }
}
export default withRouter(DisplayCardDetails);
  

